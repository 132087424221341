<template>
  <v-card tile flat class="option px-10 py-3">
    <v-data-table :headers="headers" :items="data" hide-default-footer>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.title">
            <td>{{ item.title }}</td>
            <td class="text--text text-end font-weight-regular">{{ item.discount_percent ? `-${item.discount_percent}%` : '' }}</td>
            <td class="h13 text-end text-uppercase">{{ item.price_currency }} {{ (item.price / 100).toFixed(2) }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: 'Option', align: 'start', sortable: false, value: 'title' },
        { text: 'Discount', align: 'end', sortable: false, value: 'discount_percent' },
        { text: 'Amount', align: 'end', sortable: false, value: 'price' },
      ],
    };
  },
  computed: {
    data() {
      return this.$store.getters.coursesItem.options;
    },
  },
};
</script>
