<template>
  <v-card tile flat class="lesson px-10 py-6">
    <div class="h3 mb-4">{{ item.title }}</div>
    <div class="h8">{{ item.description }}</div>
  </v-card>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>
