<template>
  <div class="course wrap d-flex flex-sm-row flex-column">
    <v-card color="accent pb-5 course-menu mx-sm-0 mx-auto d-flex flex-column justify-space-between" tile flat width="200">
      <div>
        <div class="py-4 px-6">
          <div v-if="data.status" :class="data.status == 'active' ? 'success' : 'error'" class="d-inline-block white--text px-3 py-1">
            {{ data.status.toUpperCase() }}
          </div>
        </div>
        <div :class="link == 1 ? 'hover' : ''" @click="link = 1" class="ps-10 pe-4 py-2 link">
          <div :class="link == 1 ? 'font-weight-medium' : 'text--text'" class="h8">Description</div>
        </div>
        <div v-if="data.options.length" :class="link == 2 ? 'hover' : ''" @click="link = 2" class="ps-10 pe-4 py-2 link">
          <div :class="link == 2 ? 'font-weight-medium' : 'text--text'" class="h8">Option</div>
        </div>
        <div
          v-for="(item, i) in data.lessons"
          :key="item.id"
          :class="link == i + 3 ? 'hover' : ''"
          @click="link = i + 3"
          class="ps-10 pe-4 py-2 link"
        >
          <div :class="link == i + 3 ? 'font-weight-medium' : 'text--text'" class="h8">{{ item.title }}</div>
        </div>
        <div
          v-if="data.attachments.length"
          :class="link == data.lessons.length + 3 ? 'hover' : ''"
          @click="link = data.lessons.length + 3"
          class="ps-10 pe-4 py-2 link"
        >
          <div :class="link == data.lessons.length + 3 ? 'font-weight-medium' : 'text--text'" class="h8">Attachments</div>
        </div>
      </div>
      <div class="btn-edit mt-auto text-center pt-2">
        <v-btn :to="`/course-edit/${$route.params.coursesId}`" outlined width="116" height="34">
          <img class="pe-1" src="@/assets/icon/edit.svg" /> Edit course
        </v-btn>
        <v-btn @click="deleteModal = true" text width="116" height="34" class="error--text mt-2">
          <img src="@/assets/icon/delete.svg" class="mr-1" />Remove
        </v-btn>
      </div>
    </v-card>
    <v-card tile flat width="100%" max-width="732" class="pb-4 ms-md-auto" min-height="calc(100vh - 60px)">
      <v-expand-transition>
        <div v-if="link == 1">
          <Description />
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div v-if="link == 2">
          <Option />
        </div>
      </v-expand-transition>
      <div v-for="(item, i) in data.lessons" :key="item.id">
        <v-expand-transition>
          <div v-if="link == i + 3">
            <Lesson :item="item" />
          </div>
        </v-expand-transition>
      </div>
      <v-expand-transition>
        <div v-if="link == data.lessons.length + 3">
          <Attachments />
        </div>
      </v-expand-transition>
    </v-card>
    <v-dialog v-model="deleteModal" width="568">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="h2">Remove course</div>
          <v-icon class="black--text" @click="deleteModal = false">mdi-close</v-icon>
        </div>
        <div class="h6 mb-3">Are you sure you want to delete the course {{ data.title }}?</div>
        <div class="text-right">
          <v-btn @click="deleteModal = false" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="deleteCourse" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Description from '@/components/course/Description';
import Option from '@/components/course/Option';
import Lesson from '@/components/course/Lesson';
import Attachments from '@/components/course/Attachments';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Description,
    Option,
    Lesson,
    Attachments,
  },
  data() {
    return {
      link: 1,
      deleteModal: false,
    };
  },
  mounted() {
    this.$store.dispatch('getCoursesItem');
  },
  methods: {
    async deleteCourse() {
      await this.$store.dispatch('deleteCoursesItem');
    },
  },
  computed: {
    data() {
      return this.$store.getters.coursesItem;
    },
  },
  destroyed() {
    this.$store.dispatch('setCoursesItem', { options: [], lessons: [], attachments: [] });
  },
};
</script>

<style lang="scss">
.course {
  &-menu {
    position: fixed;
    height: calc(100vh - 60px);
    overflow: auto;
    min-width: 180px;
  }
  .v-btn--outlined {
    background: #ffffff;
  }
  @media screen and (max-width: 1149px) {
    &-menu {
      position: static;
    }
  }
  @media screen and (max-width: 599px) {
    &-menu {
      height: initial;
    }
  }
}
</style>
