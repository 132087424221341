<template>
  <v-card tile flat class="description">
    <v-card flat tile min-height="180" class="description-header white"></v-card>
    <v-card tile flat class="pa-6 description-card mx-auto" width="100%" max-width="652">
      <div v-if="data.category" class="h14 text-uppercase mb-4">{{ data.category.title }}</div>
      <div class="d-flex justify-space-between mb-8">
        <div>
          <div class="h1 mb-3">{{ data.title }}</div>
          <div class="h7">{{ data.short_description }}</div>
        </div>
        <v-card flat tile max-width="120" width="100%" class="h10">
          <div class="d-flex mb-3">
            <v-card flat class="rounded-circle primary d-flex align-center justify-center me-3" width="20" height="20">
              <img height="12" src="@/assets/icon/lesson.svg" />
            </v-card>
            <div>{{ data.students_count }} student(s)</div>
          </div>
          <div class="d-flex mb-3">
            <v-card flat class="rounded-circle primary d-flex align-center justify-center me-3" width="20" height="20">
              <img height="12" src="@/assets/icon/star.svg" />
            </v-card>
            <div class="text-capitalize">{{ data.level }}</div>
          </div>
          <div class="d-flex mb-3">
            <v-card flat class="rounded-circle primary d-flex align-center justify-center me-3" width="20" height="20">
              <img height="12" src="@/assets/icon/time.svg" />
            </v-card>
            <div>{{ data.lesson_duration_minutes }} minutes</div>
          </div>
          <div class="d-flex">
            <v-card flat class="rounded-circle primary d-flex align-center justify-center me-3" width="20" height="20">
              <img height="12" src="@/assets/icon/book.svg" />
            </v-card>
            <div>{{ data.lessons.length }} lesson(s)</div>
          </div>
        </v-card>
      </div>
      <div class="h8 text--text mb-2">Description:</div>
      <div class="h8">{{ data.description }}</div>
    </v-card>
  </v-card>
</template>

<script>
export default {
  computed: {
    data() {
      return this.$store.getters.coursesItem;
    },
  },
};
</script>

<style lang="scss">
.description {
  &-header {
    background-image: url(../../assets/img/header-img.svg);
    background-repeat: repeat !important;
  }
  &-card {
    margin-top: -145px;
  }
}
</style>
