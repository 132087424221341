<template>
  <div class="px-10 py-3 attachments">
    <div v-if="data.find((item) => item.type == 'pdf')">
      <div class="text--text h8 mt-4">Files</div>
      <v-divider class="mb-2 mt-1"></v-divider>
      <div v-for="item in data" :key="item.id">
        <div v-if="item.type == 'pdf'">
          <div class="d-flex align-center">
            <div class="me-3">
              <v-img @click="openFile(item)" class="link d-flex align-end" v-if="item.picture" height="74" cover :src="item.picture">
                <v-card tile color="black" width="26" height="26" class="ms-auto d-flex align-center justify-center rounded-tl-lg">
                  <v-img tile class="filter-white" contain height="16" src="@/assets/icon/edit.svg"></v-img>
                </v-card>
              </v-img>
              <v-card v-else flat tile color="#eaeafb" width="80" height="74" class="d-flex align-center justify-center">
                <img class="d-block" src="@/assets/img/empty-file.svg" />
              </v-card>
            </div>
            <div>
              <div class="h7">{{ item.title }}.{{ item.type }}</div>
              <div><a class="url--text h7" :href="item.url" target="_blank">Open in browser</a></div>
            </div>
          </div>
          <v-divider class="my-2"></v-divider>
        </div>
      </div>
    </div>
    <div v-if="data.find((item) => item.type == 'link')">
      <div class="text--text h8 mt-4">Links</div>
      <v-divider class="mb-2 mt-1"></v-divider>
      <div v-for="item in data" :key="item.id">
        <div v-if="item.type == 'link'">
          <div class="d-flex align-center">
            <div class="me-3">
              <v-img @click="openFile(item)" class="link d-flex align-end" v-if="item.url" height="54" cover :src="item.url">
                <v-card tile color="black" width="26" height="26" class="ms-auto d-flex align-center justify-center rounded-tl-lg">
                  <v-img tile class="filter-white" contain height="16" src="@/assets/icon/edit.svg"></v-img>
                </v-card>
              </v-img>
              <v-card v-else flat tile color="#eaeafb" width="80" height="54" class="d-flex align-center justify-center">
                <img class="d-block" src="@/assets/img/empty-link.svg" />
              </v-card>
            </div>
            <div>
              <div class="h7" v-if="item.title">{{ item.title }}</div>
              <div class="h11 mb-1 text--text" v-if="item.description">{{ item.description }}</div>
              <div>
                <a class="url--text h7" :href="item.link" target="_blank">{{ item.link }}</a>
              </div>
            </div>
          </div>
          <v-divider class="my-2"></v-divider>
        </div>
      </div>
    </div>
    <div v-if="data.find((item) => item.type == 'picture')">
      <div class="text--text h8 mt-4">Photos</div>
      <div class="d-flex align-center flex-wrap justify-md-start justify-center">
        <div v-for="item in data" :key="item.id">
          <v-img
            @click="openImg(item)"
            class="me-1 mt-1 link d-flex align-end"
            v-if="item.type == 'picture'"
            height="156"
            max-width="158"
            cover
            :src="item.url"
          >
            <v-card tile color="black" width="26" height="26" class="ms-auto d-flex align-center justify-center rounded-tl-lg">
              <v-img tile class="filter-white" contain height="16" src="@/assets/icon/edit.svg"></v-img>
            </v-card>
          </v-img>
        </div>
      </div>
    </div>
    <div v-if="data.find((item) => item.type == 'video')">
      <div class="text--text h8 mt-4 mb-1">Video</div>
      <v-row>
        <v-col cols="12" md="6" v-for="item in data" :key="item.id" :class="item.type == 'video' ? '' : 'd-none'">
          <video controls :width="`100%`">
            <source :src="item.url" />
          </video>
          <div class="d-flex link" @click="openVideo(item)">
            <div>
              <div class="h7" v-if="item.title">{{ item.title }}</div>
              <div class="h11 text--text" v-if="item.description">{{ item.description }}</div>
            </div>
            <v-spacer></v-spacer>
            <v-img tile contain max-width="20" height="16" src="@/assets/icon/edit.svg"></v-img>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="modalFile" width="512">
      <v-card class="pa-4 pt-1">
        <div class="text-end mb-1">
          <v-icon @click="modalFile = false">mdi-close</v-icon>
        </div>
        <v-img max-width="480" :src="objectData.url"></v-img>
        <div class="h7 mt-1" v-if="objectData.title">{{ objectData.title }}</div>
        <div class="h11 text--text" v-if="objectData.description">{{ objectData.description }}</div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalLink" width="512">
      <v-card class="pa-4 pt-1">
        <div class="text-end mb-1">
          <v-icon @click="modalLink = false">mdi-close</v-icon>
        </div>
        <v-img max-width="480" :src="objectData.url"></v-img>
        <div class="h7 mt-1" v-if="objectData.title">{{ objectData.title }}</div>
        <div class="h11 text--text" v-if="objectData.description">{{ objectData.description }}</div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalImg" width="512">
      <v-card class="pa-4 pt-1">
        <div class="text-end mb-1">
          <v-icon @click="modalImg = false">mdi-close</v-icon>
        </div>
        <v-img max-width="480" :src="objectData.url"></v-img>
        <div class="h7 mt-1" v-if="objectData.title">{{ objectData.title }}</div>
        <div class="h11 text--text" v-if="objectData.description">{{ objectData.description }}</div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalVideo" width="600">
      <v-card class="pa-4 pt-1">
        <div class="text-end mb-1">
          <v-icon @click="modalVideo = false">mdi-close</v-icon>
        </div>
        <video controls :width="`100%`">
          <source :src="objectData.url" />
        </video>
        <div class="h7 mt-1" v-if="objectData.title">{{ objectData.title }}</div>
        <div class="h11 text--text" v-if="objectData.description">{{ objectData.description }}</div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalImg: false,
      modalFile: false,
      modalLink: false,
      modalVideo: false,
      objectData: {},
    };
  },
  methods: {
    openFile(item) {
      this.objectData = item;
      this.modalFile = true;
    },
    openLink(item) {
      this.objectData = item;
      this.modalLink = true;
    },
    openImg(item) {
      this.objectData = item;
      this.modalImg = true;
    },
    openVideo(item) {
      this.objectData = item;
      this.modalVideo = true;
    },
  },
  computed: {
    data() {
      return this.$store.getters.coursesItem.attachments;
    },
  },
};
</script>

<style lang="scss">
.attachments {
  .h11 {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 31px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
